import './App.css';
import PublicLanding from './components/Marketing/components/PublicLanding';
import OrganisationCreate from './components/Identity/components/OrganisationCreate';
import PageCreate from './components/Pages/components/PageCreate';
import SignUp from './components/Identity/components/SignUp';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AppSettingsDetail from './components/AppSettings/AppSettingsDetail';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from './components/Dashboard/components/Dashboard';
import PageDetail from './components/Pages/components/PageDetail';
import PageUpdate from './components/Pages/components/PageUpdate';
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

function App() {

  const dispatch = useDispatch();
  const identity = useSelector(state => state.identity);
  // use page detail if not pageflow.com.au domain
  let isPageFlowDomain = document.domain === 'pageflow.com.au' ? true : false; 
  // if user is not authenticated:
  if (identity.authentication.token != undefined) {
    return (
      <div className="bg-gray-100">
        <Router>
          <Routes>
            <Route path="/pages/create" exact element={<PageCreate />} />
            <Route path="/pages/update/:page_id" exact element={<PageUpdate />} />
            <Route path="/p/:page_slug" exact element={<PageDetail />} />
            <Route path="/identity/organisations/create" exact element={<OrganisationCreate />} />
            <Route path="/settings" exact element={<AppSettingsDetail />} />
            <Route path="/" exact element={<Dashboard />} />
          </Routes>
        </Router>
      </div>
    );
  } else {
    return (
      <div className="bg-gray-100">
        <Router>
          <Routes>
            <Route path="/signup" exact element={<SignUp />} />
            {isPageFlowDomain &&
              <Route path="/" exact element={<PublicLanding />} />
            }
            {!isPageFlowDomain &&
              <Route path="/" exact element={<PageDetail />} />
            }
            <Route path="/p/:page_slug" exact element={<PageDetail />} />
          </Routes>
        </Router>
      </div>
    )
  }

  // if user is authenticated:

  
}

export default App;

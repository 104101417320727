import { EnvelopeIcon, PhoneIcon, MapPinIcon } from "@heroicons/react/24/outline";

const serialize = function(obj) {
  var str = [];
  for (var p in obj)
      if (obj.hasOwnProperty(p)) {
          if (obj[p] != undefined && obj[p] != null && obj[p] != '') {
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
      }
  return str.join("&");
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function _PageItemContactCardListItem(props) {
  const page_item = props.page_item;

  const base_styles = {
    button: "w-full inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    button_colours: "rounded-md bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
  }

  const custom_styles = {
    button_colours: " bg-gray-600 hover:bg-gray-500 focus-visible:outline-gray-600 text-white "
  }

  const API_KEY = 'AIzaSyAv1qYyFnERlNhpUdv_P8GkD8gGBwUSZzs';
  const place = page_item.variables.address;
  // const src = "https://www.google.com/maps/embed/v1/MAP_MODE?key=YOUR_API_KEY&PARAMETERS"
  const src = "https://www.google.com/maps/embed/v1/place?" + serialize({
    key: API_KEY,
    q: place,
  });

  return (
    <div className="grid grid-cols-1 space-y-2">
      <span className="font-bold">{page_item.name}</span>
      
      {page_item.variables.phone &&
      <span className="flex align-middle items-center">
        <PhoneIcon className="h-4 w-4 mr-3 align-middle text-gray-500" aria-hidden="true" />
        <a 
          className="text-blue-500 underline text-sm" 
          href={"tel:" + page_item.variables.phone }>
            {page_item.variables.phone}
        </a>
      </span>
      }

      {page_item.variables.email &&
      <span className="flex align-middle items-center">
        <EnvelopeIcon className="h-4 w-4 mr-3 align-middle text-gray-500" aria-hidden="true" />
        <a 
          className=" text-blue-500 underline text-sm" 
          href={"mailto:" + page_item.variables.email }>
              {page_item.variables.email}
        </a>
      </span>
      }

      {page_item.variables.address &&
      <span className="flex align-middle items-center">
        <MapPinIcon className="h-4 w-4 mr-3 align-middle text-gray-500" aria-hidden="true" />
        <a 
          className=" text-blue-500 underline text-sm" 
          href="#">
              {page_item.variables.address}
        </a>
      </span>
      }
      
      {page_item.variables.address &&
      <iframe
        className="flex w-full rounded-lg pl-6"
        height="350"
        frameborder="0"
        referrerpolicy="no-referrer-when-downgrade"
        src={src}
        allowfullscreen>
      </iframe>
      }
      
    </div>
  )
}
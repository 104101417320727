import { legacy_createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {thunk} from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { app } from './components/App/reducers';
import { identity } from './components/Identity/reducers';
import { pages } from './components/Pages/reducers';
import { configureStore } from '@reduxjs/toolkit';


// const reducers = {
//     identity,
//     pages
// };

// const persistConfig = {
//     key: 'root',
//     storage,
//     stateReconciler: autoMergeLevel2,
// }

// const rootReducer = combineReducers

// const rootReducer = combineReducers(reducers);
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const configureStore = () => legacy_createStore(
//     persistedReducer,
//     composeWithDevTools(
//         applyMiddleware(thunk),
//     ),
// );
// export const store = configureStore;


const reducers = combineReducers({
  app,
  identity,
  pages
});

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentPage } from "../thunks";
import { useEffect, useState } from "react";
import PageItemEdit from './PageItemEdit';


export default function PageUpdate(props) {
  const dispatch = useDispatch();
  const { _params_page_slug } = useParams();
  const pages = useSelector(state => state.pages);
  const [showPageItemCreate, setShowPageItemCreate] = useState(false);

  useEffect(() => {
    dispatch(getCurrentPage({ slug: _params_page_slug }));
  }, [_params_page_slug]);

  const page = pages.currentPage;

  return (
    <div className="p-5 flex flex-1 flex-col justify-center ">
      <p className=" font-bold">Page {page.slug} Detail</p>
      {showPageItemCreate === false &&
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
          <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => { setShowPageItemCreate(true) }}
            >
              <PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Add item
          </button>
        </div>
      }

      {showPageItemCreate === true &&
        <PageItemEdit />
      }
    </div>
  );
}
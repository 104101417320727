import { SIGN_OUT } from '../Identity/actions';
import {
    UPDATE_NAVIGATION,
    UPDATE_ONBOARDING,
    UPDATE_IS_LOADING,
    UPDATE_INLINE_ITEM_ADD,
    UPDATE_ERRORS,
} from './actions';



const DEFAULT_STATE = {
    isLoading: {
        name: null,
        isLoading: false,
    },
    errors: {
        action: null,
        errors: null,
        context: null,
    },
}

export const app = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case UPDATE_NAVIGATION:
            return {
                ...state,
                navigation: action.payload.navigation,
            }
        case UPDATE_INLINE_ITEM_ADD:
            return {
                ...state,
                inlineItemAdd: action.payload.inlineItemAdd,
            }
        case UPDATE_ONBOARDING:
            return {
                ...state,
                onboarding: action.payload.onboarding,
            }
        case UPDATE_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            }
        case UPDATE_ERRORS:
            return {
                ...state,
                errors: action.payload.errors,
            }
        case SIGN_OUT:
            return DEFAULT_STATE;
        default:
            return state;
    }
}
import {
  PAGE_CREATE_LOADING,
  UPDATE_CURRENT_PAGE
} from './actions';

const DEFAULT_STATE = {
  currentPage: null
}

export const pages = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_PAGE:
      return { ...state, currentPage: action.payload.page };
    default:
      return state;
  }
}

export default function PageItemUrlForm(props) {
  return (
    <div>
      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
        URL
      </label>
      <div className="mt-2">
        <input
          id="url"
          name="text"
          type="text"
          autoComplete="text"
          required
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(e) => { 
            props.setForm({ 
              ...props.form, 
              variables: {...props.form.variables, 'url': e.target.value 
            }}); 
          }}
        />
      </div>
      <div>
        <button
          type="button"
          className="mt-2 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => props.onSubmit()}
        >
          Save
        </button>
      </div>
    </div>
  );
}
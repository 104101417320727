import { XCircleIcon } from "@heroicons/react/20/solid";
import { PhoneArrowDownLeftIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageAppPost } from "../thunks";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function _PageItemContactFormListItem(props) {
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const page_item = props.page_item;
  const [form, setForm] = useState({
    page_item_id: page_item.id,
    action: 'contact_form_app_mail'
  });
  const [showForm, setShowForm] = useState(false);

  const base_styles = {
    button: "w-full inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    button_colours: "rounded-md bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
  }

  const custom_styles = {
    button_colours: " bg-gray-600 hover:bg-gray-500 focus-visible:outline-gray-600 text-white "
  }

  if (page_item.variables.type === 'request_callback') {
    return (
      <div className="grid grid-cols-1">
        <div className="flex justify-between">
          {!props.action_bar || false &&
            <span className="font-bold">{page_item.name}</span>
          }
        </div>
        
        {!showForm &&
        <div>
          <button
            type="button"
            className={classNames(base_styles.button, custom_styles.button_colours != null ? custom_styles.button_colours : base_styles.button_colours)}
            onClick={() => setShowForm(!showForm) }
          >
            <PhoneArrowDownLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Request a Callback
          </button>
        </div>
        }
        
        {showForm &&
        <div className=" bg-white p-5 shadow-md rounded-md grid grid-cols-1 gap-y-3">
          <div className="flex justify-between">
            <span className="font-bold">{page_item.name}</span>
            <span className="font-bold" onClick={() => setShowForm(false)}>
              <XCircleIcon className="h-5 w-5 text-gray-500" />
            </span>
          </div>
            
          <div>
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
              Your name
            </label>
            <div className="">
              <input
                id="name"
                name="name"
                type="text"
                autoComplete="name"
                placeholder="Name"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => { setForm({ ...form, name: e.target.value }); }}
              />
            </div>
          </div>
          
          <div>
            <label htmlFor="contactDetails" className="block text-sm font-medium leading-6 text-gray-900">
              Your contact details
            </label>
            <div className="">
              <input
                id="contactDetails"
                name="contactDetails"
                type="text"
                autoComplete="phone"
                placeholder="Phone number or email"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => { setForm({ ...form, contact_details: e.target.value }); }}
              />
            </div>
          </div>

          <div>
            <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
              Notes
            </label>
            <div className="">
              <textarea
                id="notes"
                name="notes"
                type="textarea"
                placeholder="Additional notes so we can better service your request"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => { setForm({ ...form, notes: e.target.value }); }}
              />
            </div>
          </div>

          <div>
            {app.isLoading.isLoading !== true && 
              <button
                type="button"
                className={classNames(base_styles.button, custom_styles.button_colours != null ? custom_styles.button_colours : base_styles.button_colours)}
                onClick={() => { dispatch(pageAppPost(form)); } }
              >
                <PhoneArrowDownLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Request a Callback
              </button>
            }

            {(app.isLoading.name === form.page_item_id && app.isLoading.isLoading === true) && 
            <button
              type="submit"
              className={classNames(
                base_styles.button, 
                custom_styles.button_colours != null ? custom_styles.button_colours : base_styles.button_colours,
                "justify-center cursor-wait")}
              // className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              </span>
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </button>
            }
          </div>

        </div>
        }
        
      </div>
    )
  } else {
    return (<div></div>)
  }
  

}
import _PageItemUrlListItem from "./_PageItemUrlListItem";
import _PageItemContactCardListItem from "./_PageItemContactCardListItem";
import _PageItemServicesListItem from "./_PageItemServicesListItem";
import _PageItemGMapListItem from "./_PageItemGMapListItem";
import _PageItemContactFormListItem from "./_PageItemContactFormListItem";
import _PageItemServiceBookingListItem from "./_PageItemServiceBookingListItem";
import _PageItemFeatureCardListItem from "./_PageItemFeatureCardListItem";
import _PageItemSwiperListItem from "./_PageItemSwiperListItem";

export default function _PageItemListItem(props) {
  const page_item = props.page_item;
  const standard_container_css = "p-5 bg-white rounded-md shadow-md w-full hover:bg-blue-50 hover:cursor-pointer";
  const standard_action_button_css = "w-full";
  const standard_no_hover_container_css = "p-5 bg-white rounded-md shadow-md w-full";
  const dark_no_hover_container_css = "p-5 bg-black text-white rounded-md shadow-md w-full"

  const page_item_render_components = {
    'url': { 
        'component': (<_PageItemUrlListItem {...props} />),
        'container_css': standard_container_css
    },
    'contact_card': {
      'component': (<_PageItemContactCardListItem {...props} />), 
      'container_css': standard_no_hover_container_css
    },
    'contact_form': {
      'component': (<_PageItemContactFormListItem {...props} />), 
      'container_css': standard_action_button_css
    },
    'service_booking': {
      'component': (<_PageItemServiceBookingListItem {...props} />), 
      'container_css': standard_action_button_css
    },
    'services': {
      'component': (<_PageItemServicesListItem {...props} />), 
      'container_css': standard_no_hover_container_css
    },
    'google_map': {
      'component': (<_PageItemGMapListItem {...props} />),
      'container_css': standard_no_hover_container_css
    },
    'feature_card': {
      'component': (<_PageItemFeatureCardListItem {...props} />),
      'container_css': standard_action_button_css
    },
    'swiper': {
      'component': (<_PageItemSwiperListItem {...props} />),
      'container_css': standard_no_hover_container_css
    }
  }
  if (page_item_render_components[page_item.type] !== undefined) {
    return (
      <div 
        className={page_item_render_components[page_item.type].container_css}>      
        {page_item_render_components[page_item.type].component}
      </div>
    );
  } else {
    return null;
  }
}
import {
    ORGANISATION_INVITE_CREATE_LOADING,
    ORGANISATION_INVITE_RESEND_LOADING,
    ORGANISATION_RELATION_CREATE_LOADING,
    ORGANISATION_UPDATE_LOADING,
    REGISTER_USER_LOADING,
    updateAuthenticationToken,
    updateCurrentOrganisation,
    updateCurrentOrganisationInvites,
    updateCurrentOrganisationMembers,
    updateUser,
    UPDATE_CURRENT_ORGANISATION_LOADING,
} from './actions';
import { updateIsLoading, updateNavigation } from '../App/actions';


let base_url = 'http://127.0.0.1:8000/api/';
if (process.env.NODE_ENV == 'production') {
    base_url = 'https://www.pageflow.com.au/api/';
}

function getCookie(name) {
    if (!document.cookie) {
      return null;
    }
    const token = document.cookie.split(';')
      .map(c => c.trim())
      .filter(c => c.startsWith(name + '='));

    if (token.length === 0) {
      return null;
    }
    return decodeURIComponent(token[0].split('=')[1]);
}

export const registerUser = (user) => async (dispatch, getState) => {
    dispatch(updateIsLoading({
        name: REGISTER_USER_LOADING,
        isLoading: true,
    }));
    
    const response = await fetch(base_url + 'identities/register/', {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'post',
        body: JSON.stringify(user),
    });
    const responseBody = await response.json();
    
    dispatch(updateIsLoading({
        name: REGISTER_USER_LOADING,
        isLoading: false,
    }));
    
    // check for success
    if (response.status == 200) {
        // Update the user
        dispatch(updateUser(responseBody.user));

        // Get the new auth token
        dispatch(getAuthenticationToken({
            username: user.email,
            password: user.password,
        }));
    }
    
    // TODO handle error
};

export const getUser = () => async (dispatch, getState) => {
    const auth_token = getState().identity.authentication.token;
    document.cookie = "token="+ auth_token + ";";

    const response = await fetch(base_url + 'identities/user/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'get',
    });

    const responseBody = await response.json();

    console.log(response);
    console.log(responseBody);

    if (response.status == 200) {
        dispatch(updateUser(responseBody.user));
    }
};

export const getAuthenticationToken = (credentials) => async (dispatch) => {
    
    if (credentials.action === 'sign_in_with_google') {
        const response = await fetch(base_url + 'identities/api-token-google-auth/', {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            method: 'post',
            body: JSON.stringify(credentials),
        });
        const responseBody = await response.json();
        console.log(responseBody);
        if (response.status == 200) {        
            dispatch(updateAuthenticationToken(responseBody.token));
            document.cookie = "token="+ responseBody.token + ";";
        }
    } else {
        const response = await fetch(base_url + 'identities/api-token-auth/', {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            method: 'post',
            body: JSON.stringify(credentials),
        });
        const responseBody = await response.json();
    
        if (response.status == 200) {        
            dispatch(updateAuthenticationToken(responseBody.token));
            document.cookie = "token="+ responseBody.token + ";";
        }
    }
    
    
};

export const createOrganisation = (organisation) => async (dispatch, getState) => {
    const auth_token = getState().identity.authentication.token;

    const response = await fetch(base_url + 'identities/organisations/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'post',
        body: JSON.stringify(organisation),
    });
    const responseBody = await response.json();

    if (response.status == 201) {
        // update user organisations
        dispatch(getUser());

        // make newly created organisation the currentOrganisation
        dispatch(updateCurrentOrganisation(responseBody));
    }
}

export const getCurrentOrganisation = (organisation) => async (dispatch, getState) => {
    dispatch(updateIsLoading({
        name: UPDATE_CURRENT_ORGANISATION_LOADING,
        isLoading: true,
    }));

    const auth_token = getState().identity.authentication.token;

    const response = await fetch(base_url + 'identities/organisations/' + (organisation.id) + '/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'get',
    });

    dispatch(updateIsLoading({
        name: UPDATE_CURRENT_ORGANISATION_LOADING,
        isLoading: false,
    }));

    const responseBody = await response.json();
    if (response.status == 200) {
        dispatch(updateCurrentOrganisation(responseBody));
    }
}

export const updateOrganisation = (organisation) => async (dispatch, getState) => {
    dispatch(updateIsLoading({
        name: ORGANISATION_UPDATE_LOADING,
        isLoading: true,
    }));
    const auth_token = getState().identity.authentication.token;

    const response = await fetch(base_url + 'identities/organisations/' + organisation.id + '/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'PATCH',
        body: JSON.stringify(organisation),
    });

    dispatch(updateIsLoading({
        name: ORGANISATION_UPDATE_LOADING,
        isLoading: false,
    }));

    if (response.status == 200) {
        // update current organisation
        dispatch(getCurrentOrganisation({id: organisation.id}));
        dispatch(updateNavigation({
            ...getState().app.navigation,
            redirectTo: '/settings/',
        }));
    }
}

export const createOrganisationInvite = (organisationInvite, onSuccess) => async (dispatch, getState) => {
    dispatch(updateIsLoading({
        name: ORGANISATION_INVITE_CREATE_LOADING,
        isLoading: true,
    }));
    
    const auth_token = getState().identity.authentication.token;

    if (organisationInvite.organisation == undefined) {
        const currentOrganisation = getState().identity.currentOrganisation;
        organisationInvite.organisation = currentOrganisation.id;
    }

    const response = await fetch(base_url + 'identities/organisations/invites/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'post',
        body: JSON.stringify(organisationInvite),
    });
    const responseBody = await response.json();
    
    dispatch(updateIsLoading({
        name: ORGANISATION_INVITE_CREATE_LOADING,
        isLoading: false,
    }));
    
    if (response.status == 201) {
        dispatch(getCurrentOrganisationInvites());

        if (onSuccess !== undefined) {
            onSuccess(responseBody);
        }
    }
}

export const verifyEmail = (user_id, email_verification_token) => async (dispatch) => {
    const response = await fetch(base_url + 'identities/verify/', {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'post',
        body: JSON.stringify({
            user_id: user_id,
            email_verification_token: email_verification_token,
        }),
    });
    const responseBody = await response.json();
    console.log(responseBody);
    if (response.status == 200) {
        // update user
        dispatch(getUser());
    }
}

export const resendVerifyEmail = (email) => async (dispatch) => {    
    const response = await fetch(base_url + 'identities/verify/?resend=True&email=' + encodeURIComponent(email), {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'get',
    });
    const responseBody = await response.json();

    console.log(responseBody);
    if (response.status == 200) {
        // update user
        dispatch(getUser());
    }
}

export const resendInviteEmail = (params) => async (dispatch, getState) => {
    dispatch(updateIsLoading({
        name: ORGANISATION_INVITE_RESEND_LOADING,
        isLoading: true,
    }));
    const auth_token = getState().identity.authentication.token;
    const response = await fetch(base_url + 'identities/organisations/invites/' + params.id + '/?action=resend', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'PATCH',
    });
    dispatch(updateIsLoading({
        name: ORGANISATION_INVITE_RESEND_LOADING,
        isLoading: false,
    }));
    if (response.status == 200) {
        // update user
        dispatch(getUser());
    }
}

export const getCurrentOrganisationInvites = () => async (dispatch, getState) => {
    const auth_token = getState().identity.authentication.token;
    const currentOrganisation = getState().identity.currentOrganisation;
    const params = 'organisation_id=' + currentOrganisation.id;
    const response = await fetch(base_url + 'identities/organisations/invites/?' + params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'get',
    });

    const responseBody = await response.json();

    if (response.status == 200) {
        dispatch(updateCurrentOrganisationInvites({
            ...responseBody,
        }));
    }
};

export const createOrganisationRelation = (organisationRelation, onSuccess) => async (dispatch, getState) => {
    dispatch(updateIsLoading({
        name: ORGANISATION_RELATION_CREATE_LOADING,
        isLoading: true,
    }));
    
    const auth_token = getState().identity.authentication.token;

    if (organisationRelation.organisation == undefined) {
        const currentOrganisation = getState().identity.currentOrganisation;
        organisationRelation.organisation = currentOrganisation.id;
    }
    const response = await fetch(base_url + 'identities/organisations/members/relations/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'post',
        body: JSON.stringify(organisationRelation),
    });
    const responseBody = await response.json();
    
    dispatch(updateIsLoading({
        name: ORGANISATION_RELATION_CREATE_LOADING,
        isLoading: false,
    }));
    
    if (response.status == 201) {
        if (onSuccess !== undefined) {
            onSuccess(responseBody);
        }
    }
}

export const deleteOrganisationRelation = (params, onSuccess) => async (dispatch, getState) => {
    const auth_token = getState().identity.authentication.token;

    const response = await fetch(base_url + 'identities/organisations/members/relations/' + params.id + '/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'DELETE',
    });

    if (response.status == 204) {
        if (onSuccess !== undefined) {
            onSuccess(response);
        }
    }
}

export const getCurrentOrganisationMembers = () => async (dispatch, getState) => {
    const auth_token = getState().identity.authentication.token;
    const currentOrganisation = getState().identity.currentOrganisation;
    const params = 'organisation_id=' + currentOrganisation.id;
    const response = await fetch(base_url + 'identities/organisations/members/?' + params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'get',
    });

    const responseBody = await response.json();

    if (response.status == 200) {
        dispatch(updateCurrentOrganisationMembers({
            ...responseBody,
        }));
    }
}

export const deleteOrganisationInvite = (params) => async (dispatch, getState) => {
    const auth_token = getState().identity.authentication.token;

    const response = await fetch(base_url + 'identities/organisations/invites/' + params.id + '/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'DELETE',
    });

    if (response.status == 204) {
        dispatch(getCurrentOrganisationInvites());

        dispatch(updateNavigation({
            ...getState().app.navigation,
            redirectTo: '/settings/manage-users/',
        }));
    }
}


export const deleteOrganisationMember = (params) => async (dispatch, getState) => {
    const auth_token = getState().identity.authentication.token;

    const response = await fetch(base_url + 'identities/organisations/members/' + params.id + '/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'DELETE',
    });

    if (response.status == 204) {
        // dispatch(updateCurrentOrganisation({
        //     ...currentOrganisation,
        //     members: responseBody,
        // }));
        dispatch(getCurrentOrganisationMembers());

        // dispatch(updateNavigation({
        //     ...getState().app.navigation,
        //     redirectTo: '/settings/manage-users/',
        // }));
    }
}


export const authorizeSlackApp = (params) => async (dispatch, getState) => {
    const auth_token = getState().identity.authentication.token;
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
    }
    if (auth_token !== undefined) {
        headers['Authorization'] = 'Token ' + auth_token;
    }
    params.request_type = 'authorize_slack_account';
    const response = await fetch(base_url + 'identities/slack/auth/', {
        headers: headers,
        method: 'post',
        body: JSON.stringify(params),
    });
    const responseBody = await response.json();

    if (responseBody.auth_token !== undefined) {
        dispatch(updateAuthenticationToken(responseBody.auth_token));
        document.cookie = "token="+ responseBody.auth_token + ";";
    }

    if (response.status == 200) {
        // update user organisations
        dispatch(getUser());

        // make newly created organisation the currentOrganisation
        dispatch(updateCurrentOrganisation(responseBody));
    }
}

export const connectUserSlackAppConnection = (params) => async (dispatch, getState) => {
    const auth_token = getState().identity.authentication.token;
    const response = await fetch(base_url + 'identities/slack/connect/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + auth_token,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        method: 'post',
        body: JSON.stringify(params),
    });
    const responseBody = await response.json();
    console.log(responseBody);
    if (response.status == 200) {
        // update user so connections gets updated as well
        dispatch(getUser());
    }
}
import { useDispatch } from "react-redux";
import _SignUpForm from "./_SignUpForm";
import { registerUser } from "../thunks";
import { useState } from "react";

export default function SignUp() {

  const dispatch = useDispatch();

  const [form, setForm] = useState({ email: '', password: '' });


  function submitForm() {

    dispatch(registerUser(form));
    return;
  }
  
  return (
    <div>
      <_SignUpForm onSubmit={submitForm} form={form} setForm={setForm} />
    </div>
  );
}

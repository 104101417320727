import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentPage } from "../thunks";
import { useEffect } from "react";
import _PageItemListItem from './_PageItemListItem';
import _PageItemActionBarListItem from "./_PageItemActionBarListItem";
import _PageItemSwiperListItem from "./_PageItemSwiperListItem";

export default function PageDetail(props) {
  const dispatch = useDispatch();
  const pages = useSelector(state => state.pages);
  const _params = useParams();
  // const _params_page_slug = props.match.params.page_slug;
  // const _params_page_slug = "props.match.params.page_slug";



  useEffect(() => {
    let url = document.domain;
    if (url.startsWith("www.")) {
        url = url.replace("www.", "");
    }

    let isPageFlowDomain = (url === 'pageflow.com.au') ? true : false; 
    if (!isPageFlowDomain) {
      dispatch(getCurrentPage({ domain: url }));
    } else {
      dispatch(getCurrentPage({ slug: _params.page_slug }));
    }
  }, [_params]);

  const page = pages.currentPage;

  if (page === null || page === undefined) {
    return (<div>loading</div>);
  }


  let page_item_logo = null;
  document.title = page.name;

  page.page_items.map(item => {
    if (item.type === 'profile_img' && item.variables.img_url) {
      page_item_logo = item.variables.img_url;
    }
  });

  return (
    <div className="p-5 bg-gray-100 container mx-auto lg:w-1/2 shadow-lg">
      <div className="p-5 bg-gray-600 rounded-lg shadow-md mb-5">
        <img src={page_item_logo} className=" h-52 w-52 rounded-xl mx-auto" />
        <p className="font-bold text-xl text-center text-white mt-4">{page.name}</p>
        <p className="text-sm text-center text-white">{page.description}</p>
      </div>

      <div className="w-full grid grid-cols-1 gap-y-5">        
        {/* <div className="w-full grid grid-flow-col justify-between p-5">
          {page.page_items.map(page_item => {
            return (<_PageItemActionBarListItem page_item={page_item} action_bar="true" />);
          })}
        </div> */}

        {/* <_PageItemSwiperListItem /> */}
        {page.page_items.map(page_item => {
          if (page_item.is_active) {
            return (
              <_PageItemListItem page_item={page_item}/>
            );
          } else { return; }
        })}
      </div>
    </div>
  );
}
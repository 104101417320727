export const PAGE_CREATE_LOADING = 'PAGE_CREATE_LOADING';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const updateCurrentPage = (page) => ({
  type: UPDATE_CURRENT_PAGE,
  payload: { page }
});

export const UPDATE_PAGE_FEED = 'UPDATE_PAGE_FEED';
export const updatePageFeed = (pages) => ({
  type: UPDATE_PAGE_FEED,
  payload: { pages }
});

export const CREATE_PAGE_ITEM = 'CREATE_PAGE_ITEM';
export const createPageItem = (pageItem) => ({
  type: UPDATE_PAGE_ITEM,
  payload: { pageItem }
});

export const UPDATE_PAGE_ITEM = 'UPDATE_PAGE_ITEM';
export const updatePageItem = (pageItem) => ({
  type: UPDATE_PAGE_ITEM,
  payload: { pageItem }
});
import { updateIsLoading } from "../App/actions";
import { updateCurrentPage, updatePageFeed } from "./actions";

let base_url = 'http://127.0.0.1:8000/api/';
if (process.env.NODE_ENV == 'production') {
  base_url = 'https://www.pageflow.com.au/api/';
}

function getCookie(name) {
  if (!document.cookie) {
    return null;
  }
  const token = document.cookie.split(';')
    .map(c => c.trim())
    .filter(c => c.startsWith(name + '='));

  if (token.length === 0) {
    return null;
  }
  return decodeURIComponent(token[0].split('=')[1]);
}

const serialize = function(obj) {
  var str = [];
  for (var p in obj)
      if (obj.hasOwnProperty(p)) {
          if (obj[p] != undefined && obj[p] != null && obj[p] != '') {
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
      }
  return str.join("&");
}

export const createPage = (page) => async (dispatch, getState) => {
  const auth_token = getState().identity.authentication.token;
  
  const response = await fetch(base_url + 'pages/', {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + auth_token,
        'X-CSRFToken': getCookie('csrftoken'),
    },
    method: 'post',
    body: JSON.stringify(page),
  });

  if (response.status === 201) {
    // update content
    // dispatch(getContent());
    console.log(response);
  } else if (response.status === 400) {
    console.log(response);
  }
}

export const getPages = (params) => async (dispatch, getState) => {
  const auth_token = getState().identity.authentication.token;
  if (params == undefined) {
      params = {};
  }

  const response = await fetch(base_url + 'pages/?' + serialize(params), {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + auth_token,
          'X-CSRFToken': getCookie('csrftoken'),
      },
      method: 'get',
  });

  const responseBody = await response.json();

  if (response.status == 200) {
      dispatch(updatePageFeed(responseBody));
  }
}

export const getCurrentPage = (params) => async (dispatch, getState) => {
  const auth_token = getState().identity.authentication.token;

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken'),
  }

  if (auth_token !== undefined) {
    headers['Authorization'] = 'Token ' + auth_token;
  }

  if (params == undefined) {
    params = {};
  }

  const response = await fetch(base_url + 'pages/?' + serialize(params), {
      headers: {...headers},
      method: 'get',
  });

  const responseBody = await response.json();

  if (response.status == 200) {
      dispatch(updateCurrentPage(responseBody.results[0]));
  }
}

export const pageAppPost = (form) => async (dispatch, getState) => {
  dispatch(updateIsLoading({
    name: form.page_item_id,
    isLoading: true,
  }));
  const auth_token = getState().identity.authentication.token;

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken'),
  }

  if (auth_token !== undefined) {
    headers['Authorization'] = 'Token ' + auth_token;
  }

  const response = await fetch(base_url + 'pages/app/', {
      headers: {...headers},
      method: 'post',
      body: JSON.stringify(form),
  });

  dispatch(updateIsLoading({
    name: null,
    isLoading: false,
  }));

  if (response.status === 200) {
    // update content
    // dispatch(getContent());
    console.log(response);
  } else if (response.status === 400) {
    console.log(response);
  }
}
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { createOrganisation, createPage } from '../thunks';

import { CheckCircleIcon } from '@heroicons/react/20/solid';



export default function OrganisationCreate() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    'name': '',
    'url': '',
  });

  function submitForm() {
    dispatch(createOrganisation(form))
  }

  return (
    <div className="p-5">
      <h1 className="">Create your organisation</h1>
      <p>You'll be able to manage all of your pages under your organisation and even invite teammates to collaborate with you.</p>
      <form className="">
        <div className="mt-2">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Display name
          </label>
          <div className="">
            <input
              type="text"
              name="pageName"
              id="pageName"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Display Name"
              onChange={(e) => { setForm({ ...form, name: e.target.value }); }}
            />
          </div>
        </div>

        <div className="mt-4">
          <button
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => { submitForm(); }}
          >
            <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Create organisation
          </button>
        </div>
      </form>
    </div>
  );
}
// AIzaSyAv1qYyFnERlNhpUdv_P8GkD8gGBwUSZzs

const serialize = function(obj) {
  var str = [];
  for (var p in obj)
      if (obj.hasOwnProperty(p)) {
          if (obj[p] != undefined && obj[p] != null && obj[p] != '') {
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
      }
  return str.join("&");
}

export default function _PageItemGMapListItem(props) {
  const page_item = props.page_item;
  const API_KEY = 'AIzaSyAv1qYyFnERlNhpUdv_P8GkD8gGBwUSZzs';
  const place = page_item.variables.place_query;
  // const src = "https://www.google.com/maps/embed/v1/MAP_MODE?key=YOUR_API_KEY&PARAMETERS"
  const src = "https://www.google.com/maps/embed/v1/place?" + serialize({
    key: API_KEY,
    q: place,
  });


  return (
    <div className="grid grid-cols-1">
      <span className="font-bold mb-2">{page_item.name}</span>
      <iframe
        className="flex w-full rounded-md"
        height="350"
        frameborder="0"
        referrerpolicy="no-referrer-when-downgrade"
        src={src}
        allowfullscreen>
      </iframe>
    </div>
  )
}
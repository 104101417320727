// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default function _PageItemSwiperListItem(props) {
  const page_item = props.page_item;
  return (
    <div>
      <span className="font-bold">{page_item.name}</span>
      <swiper-container 
        className=""
        pagination="true" 
        pagination-clickable="true"
        space-between="50"
        centered-slides="true" 
        autoplay-delay="2500" 
        autoplay-disable-on-interaction="false">
        {page_item.variables.slides.map(slide => {
          return (
            <swiper-slide className="">
              <div className="pb-2 mb-5 ">
                <p className="font-bold text-sm">{slide.name}</p>
                <div className="grid grid-cols-1 space-y-2">
                  <div className="">
                    <img src={slide.img_url} className="w-full rounded-xl" />
                  </div>
                  <div>
                    <p className="text-sm text-justify">{slide.description}</p>
                  </div>
                </div>

              </div>

            </swiper-slide>
          );
        })}
      </swiper-container>
    </div>
  );
}
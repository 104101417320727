import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createPage } from '../thunks';

import { CheckCircleIcon } from '@heroicons/react/20/solid';



export default function PageCreate() {
  const dispatch = useDispatch();
  const identity = useSelector(state => state.identity);
  const [form, setForm] = useState({
    'organisation': identity.currentOrganisation.id,
    'name': '',
    'slug': ''
  });
  // console.log(identity.user.organisations);
  // identity.user.organisations.map((organisation, index) => {
  //   console.log(organisation.name);
  // });
  function submitForm() {
    dispatch(createPage(form))
  }

  return (
    <div className="">
      <h1 className="p-5 pb-0">Page Create</h1>
      <form className="p-5">
        <div>
          <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
            Organisation
          </label>
          <select
            id="location"
            name="location"
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue={identity.currentOrganisation.id}
            onChange={(e) => { setForm({ ...form, organisation_id: e.target.value }); }}
          >
            {identity.user.organisations.map((organisation, index) => {
              return (<option key={index} value={organisation.id}>{organisation.name}</option>);
            })}
          </select>
        </div>

        <div className="mt-2">
          <label htmlFor="pageName" className="block text-sm font-medium leading-6 text-gray-900">
            Display name
          </label>
          <div className="">
            <input
              type="text"
              name="pageName"
              id="pageName"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Display Name"
              onChange={(e) => { setForm({ ...form, name: e.target.value }); }}
            />
          </div>
        </div>

        <div className="mt-2">
          <label htmlFor="pageSlug" className="block text-sm font-medium leading-6 text-gray-900 ">
            Page URL
          </label>
          <div className="">
            <input
              type="text"
              name="pageSlug"
              id="pageSlug"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Page URL"
              onChange={(e) => { setForm({ ...form, slug: e.target.value }); }}
            />
          </div>
        </div>

        <div className="mt-4">
          <button
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => { submitForm(); }}
          >
            <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Create page
          </button>
        </div>
      </form>
    </div>
  );
}
import { useDispatch, useSelector } from "react-redux";
import { getPages } from "../../Pages/thunks";
import { getUser } from "../../Identity/thunks";
import _DashboardPageListItem from "./_DashboardPageListItem";

export default function Dashboard() {
  
  // pages grouped by organisation
  const identity = useSelector(state => state.identity);
  const dispatch = useDispatch();
  // dispatch(getUser());



  return (
    <div>
      Dashboard
      <div>
        {identity.user.organisations.map((organisation) => {
          return (
            <ul role="list"
              className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
              >
              <p>{organisation.name}</p>
              {organisation.pages != undefined && organisation.pages.map(page => {
                return (
                  <_DashboardPageListItem page={page} linkTo={'/pages/' + page.slug} />
                );
              })}
            </ul>
          )
        })}
      </div>
    </div>
  );
}
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

export default function _PageItemServicesListItem(props) {
  const page_item = props.page_item;

  return (
    <div>
      <span className="font-bold">{page_item.name}</span>
      <div className="grid grid-cols-3 mt-2">
        {page_item.variables.map(item => {
          return (
            <div className="text-center align-middle">
              <img src={item.img_url} className="h-10 w-10 block mx-auto" />
              <span className="text-sm">{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
    
  )
}
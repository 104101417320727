import {
  UPDATE_USER,
  UPDATE_AUTHENTICATION_TOKEN,
  UPDATE_CURRENT_ORGANISATION,
  SIGN_OUT,
  UPDATE_CURRENT_ORGANISATION_INVITES,
  UPDATE_CURRENT_ORGANISATION_MEMBERS,
} from './actions';


const DEFAULT_STATE = {
  authentication: {},
  user: {},
  currentOrganisation: {},
  currentOrganisationMembers: { results: [] },
  currentOrganisationInvites: { results: [] },
}

export const identity = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
      case UPDATE_USER:
          return {...state, user: action.payload.user};
      case UPDATE_AUTHENTICATION_TOKEN:
          return {...state, authentication: {token: action.payload.token}};
      case UPDATE_CURRENT_ORGANISATION:
          return {
              ...state,
              currentOrganisation: action.payload.organisation, 
          };
      case UPDATE_CURRENT_ORGANISATION_MEMBERS:
          return {
              ...state,
              currentOrganisationMembers: action.payload.organisationMembers,
          }
      case UPDATE_CURRENT_ORGANISATION_INVITES:
          return {
              ...state,
              currentOrganisationInvites: action.payload.organisationInvites,
          }
      case SIGN_OUT:
          return DEFAULT_STATE;
      default:
          return state;
  }
}
export const REGISTER_USER_LOADING = 'REGISTER_USER_LOADING';
export const ORGANISATION_INVITE_CREATE_LOADING = 'ORGANISATION_INVITE_CREATE_LOADING';
export const ORGANISATION_INVITE_RESEND_LOADING = 'ORGANISATION_INVITE_RESEND_LOADING';
export const ORGANISATION_UPDATE_LOADING = 'ORGANISATION_UPDATE_LOADING';
export const ORGANISATION_RELATION_CREATE_LOADING = 'ORGANISATION_RELATION_CREATE_LOADING';

export const UPDATE_AUTHENTICATION_TOKEN = 'UPDATE_AUTHENTICATION_TOKEN';
export const updateAuthenticationToken = (token) => ({
    type: UPDATE_AUTHENTICATION_TOKEN,
    payload: { token },
});

export const UPDATE_USER = 'UPDATE_USER';
export const updateUser = (user) => ({
    type: UPDATE_USER,
    payload: { user },
});

export const UPDATE_CURRENT_ORGANISATION_LOADING = 'UPDATE_CURRENT_ORGANISATION';
export const UPDATE_CURRENT_ORGANISATION = 'UPDATE_CURRENT_ORGANISATION';
export const updateCurrentOrganisation = (organisation) => ({
    type: UPDATE_CURRENT_ORGANISATION,
    payload: { organisation },
});

export const UPDATE_CURRENT_ORGANISATION_MEMBERS = 'UPDATE_CURRENT_ORGANISATION_MEMBERS';
export const updateCurrentOrganisationMembers = (organisationMembers) => ({
    type: UPDATE_CURRENT_ORGANISATION_MEMBERS,
    payload: { organisationMembers },
});

export const UPDATE_CURRENT_ORGANISATION_INVITES = 'UPDATE_CURRENT_ORGANISATION_INVITES';
export const updateCurrentOrganisationInvites = (organisationInvites) => ({
    type: UPDATE_CURRENT_ORGANISATION_INVITES,
    payload: { organisationInvites },
});

export const SIGN_OUT = 'SIGN_OUT';
export const signOut = () => ({
    type: SIGN_OUT,
    payload: {},
});

export default function _PageItemFeatureCardListItem(props) {
  const page_item = props.page_item;
  
  return (
    <div className="shadow-md rounded-md">
      <div className=" ">
      {/* <img src={page_item.variables.img_url} className="w-full " /> */}
        <div className="rounded-md rounded-bl-none rounded-br-none overflow-hidden brightness-50">
          <video autoPlay loop muted playsInline>
            <source src={page_item.variables.video_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        
        <div className="  p-5 pt-2 bg-white rounded-md rounded-tl-none rounded-tr-none">
          <p className="font-bold">{page_item.name}</p>
          <span className="text-sm text-justify">
          {page_item.variables.description}
          </span>
        </div>
      </div>
      
    </div>
  );
}
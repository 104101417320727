export const UPDATE_NAVIGATION = 'UPDATE_NAVIGATION';
export const updateNavigation = (navigation) => ({
    type: UPDATE_NAVIGATION,
    payload: { navigation },
});

export const UPDATE_ONBOARDING = 'UPDATE_ONBOARDING';
export const updateOnboarding = (onboarding) => ({
    type: UPDATE_ONBOARDING,
    payload: { onboarding },
});

export const UPDATE_IS_LOADING = 'UPDATE_IS_LOADING';
export const updateIsLoading = (isLoading) => ({
    type: UPDATE_IS_LOADING,
    payload: { isLoading }
});

export const UPDATE_INLINE_ITEM_ADD = 'UPDATE_INLINE_ITEM_ADD';
export const updateInlineItemAdd = (inlineItemAdd) => ({
    type: UPDATE_INLINE_ITEM_ADD,
    payload: { inlineItemAdd }
});

export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const updateErrors = (errors) => ({
    type: UPDATE_ERRORS,
    payload: { errors }
});
import { ChevronRightIcon, EnvelopeIcon, LinkIcon, MegaphoneIcon, PaperClipIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { useState } from 'react';
import PageItemUrlForm from './PageItemUrlForm';
import { useDispatch } from 'react-redux';

export default function PageItemEdit(props) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    page_item_id: props.page_item_id !== undefined ? props.page_item_id : null,
    variables: {},
    item_type: false 
  });

  const projects = [
    { name: 'URL', item_type: 'url', icon: (<LinkIcon className="h-5" />), href: '#', bgColor: 'bg-blue-600' },
    { name: 'Attachment', item_type: 'attachment', icon: (<PaperClipIcon className="h-5" />), href: '#', bgColor: 'bg-blue-600' },
    { name: 'Phone Number', item_type: 'phone_number', icon: (<PhoneIcon className="h-5" />), href: '#', bgColor: 'bg-blue-600' },
    { name: 'Contact Form', item_type: 'contact_form', icon: (<EnvelopeIcon className="h-5" />), href: '#', bgColor: 'bg-blue-600' },
    { name: 'Social Links', item_type: 'social_links', icon: (<MegaphoneIcon className="h-5" />), href: '#', bgColor: 'bg-blue-600' },
  ]

  function onSubmitForm() {
    console.log(form);
    // TODO
    // if (form.page_item_id != null) {
    //   dispatch(pageItemUpdate(form));
    // } else {
    //   dispatch(pageItemCreate(form));
    // }
  }
  
  function renderItemTypeForm() {
    if (form.item_type === 'url') {
      return (
        <PageItemUrlForm form={form} setForm={setForm} onSubmit={onSubmitForm} pageItemId={props.page_item_id} />
      )
    } else {
      return (
        <div>Not selected</div>
      );
    }
  }
  
  return (
    <div>
      Page Item Create
      <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
        {projects.map((project) => (
          <li key={project.name} className="col-span-1 flex rounded-md shadow-sm">
            <div
              className={classNames(
                project.bgColor,
                'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
              )}
            >
              {project.icon}
            </div>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <a href={project.href} className="font-medium text-gray-900 hover:text-gray-600">
                  {project.name}
                </a>
                {/* <p className="text-gray-500">{project.members} Members</p> */}
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => { setForm({...form, item_type: project.item_type}) }}
                >
                  <span className="sr-only">Open options</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {renderItemTypeForm()}
    </div>
  );
}
import Hero from "./Hero";

export default function PublicLanding() {
  return (
    <div>
      <Hero />
    </div>
  );
}




